<template>
    <div class="home-banner an-blog-InDown"
         :style="{backgroundImage: 'url(' + $store.getters.getBackground('home')}">
        <div class="banner-container">
            <h1 class="blog-title animate__animated animate__fadeIn">{{ blogInfo.blog_name }}的博客</h1>
            <div class="blog-intro">{{obj.output}}<span class="typing">|</span></div>
        </div>
        <div class="blog-contact mt-4 d-md-none" hidden>
            <a :href="qqUrl" class="mr-5 iconfont iconqq"></a>
            <a :href="githubUrl" target="_blank" class="mr-5 iconfont icongithub"></a>
            <a :href="giteeUrl" target="_blank" class="iconfont icongitee-fill-round"></a>
        </div>
        <div class="scroll-down" @click="scrollDown">
            <v-icon color="#fff" size="40" class="scroll-down-effects">
                mdi-chevron-down
            </v-icon>
        </div>
    </div>
</template>

<script>
  import EasyTyper from 'easy-typer-js'
  import axios from 'axios'
  import {WebsiteInfoConfigMixin} from "../../../components/mixin";

  export default {
    name: "HomeTitle",
    mixins: [WebsiteInfoConfigMixin],
    data() {
      return {
        obj: {
          output: "",
          isEnd: false,
          speed: 300,
          singleBack: false,
          sleep: 0,
          type: "rollback",
          backSpeed: 40,
          sentencePause: true
        },
      }
    },
    created() {
      this.init()
    },
    methods: {
      // 初始化
      init() {
        window.scrollTo(0, 0)
        // 一言Api进行打字机循环输出效果
        axios.get("https://v1.hitokoto.cn/?c=i")
          .then(res => {
            return res.data;
          })
          .then(({hitokoto}) => {
            this.initTyped(hitokoto);
          });
      },
      initTyped(input, fn, hooks) {
        const obj = this.obj;
        // eslint-disable-next-line no-unused-vars
        const typed = new EasyTyper(obj, input, fn, hooks);
      },
      /*向下滚动*/
      scrollDown() {
        window.scrollTo({
          behavior: "smooth",
          top: document.documentElement.clientHeight
        });
      }
    }
  }
</script>

<style scoped>

    @keyframes typing {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    @keyframes suspension {
        0% {
            transform: translateY(-50%);
            opacity: 1;
        }


        100% {
            transform: translateY(0%);
            opacity: 0.4;
        }
    }


    .home-banner {
        position: absolute;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        background: #49b1f5 no-repeat center bottom;
        background-size: cover;
        animation-duration: 1s;
    }

    .banner-video video {
        width: 100%;
        height: 100%;
    }

    .banner-container {
        margin-top: 40vh;
        line-height: 1.5;
        color: #eee;
        text-align: center;
    }

    .blog-title {
        font-size: 40px;
        animation-delay: 1s;
        animation-duration: 1s;
    }

    .blog-intro {
        font-size: 25px;
    }

    .blog-contact {
        display: block;
        text-align: center;
    }

    .blog-contact a {
        font-size: 1.4rem;
        color: #fff !important;
    }


    .scroll-down {
        position: absolute;
        right: 0;
        left: 0;
        bottom: 0;
        line-height: 2;
        text-align: center;
    }


    .scroll-down-effects {
        animation: suspension 1s infinite alternate;
    }


    .typing {
        animation: typing 0.3s infinite alternate;
    }


    @media screen and (max-width: 750px) {
        .blog-title {
            font-size: 30px;
        }

        .blog-intro {
            font-size: 15px;
        }


        .home-banner {
            background-position: 20% 50%;
            background-size: cover;
        }
    }

</style>
