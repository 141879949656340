<template>
    <div>
        <home-title v-show="$route.path === '/'"/>
        <home-main/>
    </div>
</template>

<script>
  import HomeTitle from "./childComps/HomeTitle";
  import HomeMain from "./childComps/HomeMain";

  export default {
    name: "Home",
    components: {
      HomeTitle,
      HomeMain
    },
  }
</script>

<style scoped>

</style>
