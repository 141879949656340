<template>
    <v-row class="home-container">
        <!--文章列表-->
        <v-col md="9" cols="12" class="articles-main">
            <v-card class="animate__animated animate__fadeInUp" elevation="24" v-for="(article,index) in articlesList">
                <router-link tag="div" :to="'/articles/'+article.id" class="articles"
                             :class="{'article-right': (index+1)%2!== 0 }">
                    <div class="article-img">
                        <v-img class="article-image" height="100%" width="100%"
                               :src='article.articles_image_url'></v-img>
                    </div>
                    <div class="article-wrapper">
                        <div class="article-title">{{ article.articles_title }}</div>
                        <div class="article-info">
                            <span v-show="isTopping(article.articles_is_topping)" class="is-top  pr-1"><v-icon
                                    color="#ff0000"
                                    size="18"
                            >mdi-clipboard-arrow-up</v-icon></span>
                            <span v-show="isTopping(article.articles_is_topping)">|</span>
                            <span class="pl-1 pr-1"><v-icon size="18"
                                                            class="mr-1">mdi-timetable</v-icon>{{ article.update_time }}</span>
                            <span>|</span>
                            <router-link tag="span" :to="'/categories/'+article.categorie.id" class="pl-1 pr-1">
                                <v-icon size="18" class="mr-1">mdi-dresser</v-icon>
                                {{article.categorie.categorie_name}}
                            </router-link>
                            <span>|</span>
                            <span class="pl-1 pr-1">
                                <router-link tag="span" :to="'/tags/'+tag.id" :key="tag.id"
                                             v-for="(tag) in article.tags"><v-icon
                                        size="18">mdi-tag-multiple</v-icon>{{tag.tag_name}}
                                </router-link>
                            </span>
                        </div>
                        <div class="article-content">{{article.article_describe}}
                        </div>
                    </div>
                </router-link>
            </v-card>
            <infinite-loading spinner="spiral" @infinite="infiniteHandler">
                <span slot="no-more"></span>
            </infinite-loading>
        </v-col>
        <!--博主信息-->
        <v-col md="3" cols="12" class="home-blog-info">
            <div class="home-blog-wrapper">
                <v-card elevation="24" class="author pa-5 d-md-block d-none animate__animated animate__zoomIn">
                    <div class="author-wrapper">
                        <v-img class="author-img mt-3" width="110px" height="110px"
                               :src="blogInfo.user_image_url"></v-img>
                        <div class="author-name mt-3">{{blogInfo.blog_name}}</div>
                        <div class="author-motto  mt-2">{{blogInfo.motto}}</div>
                    </div>
                    <div class="blog-info-wrapper mt-6">
                        <div class="blog-info-data">
                            <span>文章</span>
                            <span>{{ countInfo.article_count }}</span>
                        </div>
                        <div class="blog-info-data">
                            <span>分类</span>
                            <span>{{ countInfo.categorie_count }}</span>
                        </div>
                        <div class="blog-info-data">
                            <span>标签</span>
                            <span>{{ countInfo.tag_count }}</span>
                        </div>
                    </div>

                    <!--加入书签-->
                    <div @click="bookmark" class="blog-bookmark">
                        <v-icon color="#fff" size="18" class="mr-1">mdi-bookmark</v-icon>
                        加入书签
                    </div>
                    <!--社交信息-->
                    <div class="card-info-social mt-4">
                        <a :href="qqUrl" class="iconfont iconqq" target="_blank"></a>
                        <a :href="githubUrl" class=" iconfont icongithub" target="_blank"></a>
                        <a :href="giteeUrl" class="iconfont icongitee-fill-round" target="_blank"></a>
                    </div>
                </v-card>
                <!--公告-->
                <v-card elevation="24" class="hot mt-4 pa-6 d-md-block d-none animate__animated animate__zoomIn">
                    <span class="mb-2"><v-icon size="18" color="red" class="mr-1 blog-bell">mdi-bell</v-icon>公告</span>
                    <!--                    <p>博客改版上线，项目源码在上方Github处，交流群号700708723，感谢大家支持。</p>-->
                    <p>{{webInfo.notice}}</p>
                </v-card>
                <!--网站资讯-->
                <v-card class="web-info mt-5 pa-4 d-md-block d-none animate__animated animate__zoomIn">
                    <span class="mb-2"><v-icon size="18" class="mr-1">mdi-chart-line</v-icon> 网站资讯</span>
                    <p><span>运行时间:</span><span>{{time}}</span></p>
                    <p><span>总访问量:</span><span>{{countInfo.visits_count}}</span></p>
                </v-card>
            </div>
        </v-col>

    </v-row>
</template>

<script>
  import {WebsiteInfoConfigMixin} from "../../../components/mixin";
  import {getArticlesListApi} from "../../../network/interface";
  import InfiniteLoading from 'vue-infinite-loading';

  export default {
    name: "HomeMain",
    mixins: [WebsiteInfoConfigMixin],
    data() {
      return {
        articlesList: [],
        time: "",
        pageInfo: {
          pageNum: 1,
          pageSize: 4,
          total: 0,
          totalPages: 0,
          firstLoad: true,
        },

      }
    },
    created() {

      this.timer = setInterval(this.runTime, 1000);

    },
    components: {InfiniteLoading},
    methods: {
      isTopping(value) {
        return Boolean(parseInt(value))
      },
      runTime() {
        var timeold =
          new Date().getTime() -
          new Date(this.webInfo.website_create_time).getTime();
        var msPerDay = 24 * 60 * 60 * 1000;
        var daysold = Math.floor(timeold / msPerDay);
        var str = "";
        var day = new Date();
        str += daysold + "天";
        str += day.getHours() + "时";
        str += day.getMinutes() + "分";
        str += day.getSeconds() + "秒";
        this.time = str;
      },
      infiniteHandler($state) {
        // 这里模仿加载延迟1秒钟
        if (this.pageInfo.firstLoad) {
          getArticlesListApi({pageNum: this.pageInfo.pageNum, pageSize: this.pageInfo.pageSize}).then(resp => {
            this.pageInfo.firstLoad = false
            this.articlesList = this.articlesList.concat(resp.data.result)
            this.pageInfo.total = resp.data.total
            this.pageInfo.totalPages = resp.data.totalPages
            $state.loaded()
          })
        } else {
          if (this.pageInfo.totalPages > this.pageInfo.pageNum) {
            this.pageInfo.pageNum += 1
            getArticlesListApi({pageNum: this.pageInfo.pageNum, pageSize: this.pageInfo.pageSize}).then(resp => {
              this.articlesList = this.articlesList.concat(resp.data.result)
              this.pageInfo.total = resp.data.total
              this.pageInfo.totalPages = resp.data.totalPages
              $state.loaded()
            })
          } else {
            $state.complete()
          }
        }

      },
      bookmark() {
        this.$toast({state: "success", message: "使用 CTRL + D 保存到书签"})
      }
    }
  }
</script>

<style scoped>


    .home-container {
        max-width: 1200px;
        margin: calc(100vh) auto 0 auto;
        padding: 0 3px;
    }

    .articles {
        display: flex;
        height: 280px;
        margin-top: 20px;
    }

    .article-right {
        flex-direction: row-reverse;
    }


    .article-image {
        transition: all 1s;
    }


    .articles:hover .article-image {
        transform: scale(1.1);
    }

    .article-img {
        border-radius: 12px 0 0 12px !important;
        overflow: hidden;
        width: 45%;
    }

    .article-right .article-img {
        border-radius: 0 8px 8px 0 !important;
    }

    .article-wrapper {
        padding: 50px 40px 0;
        width: 55%;
    }

    .article-title {
        font-size: 1.6rem;
    }

    .article-info {
        margin: 10px 0;
        font-size: 0.9rem;
    }

    .article-content {
        line-height: 1.8;
        overflow: hidden;
        text-overflow: ellipsis;
        /* 弹性伸缩盒子模型显示 */
        display: -webkit-box;
        /* 限制在一个块元素显示的文本的行数 */
        -webkit-line-clamp: 3;
        /* 设置或检索伸缩盒对象的子元素的排列方式 */
        -webkit-box-orient: vertical;
    }


    @media screen and (max-width: 750px) {
        .articles-main {
            padding: 7px !important;
        }

        .articles {
            flex-direction: column;
            height: 400px;
            margin-top: 20px;
        }

        .article-img {
            border-radius: 8px 8px 0 0 !important;
            overflow: hidden;
            height: 50%;
            width: 100%;
        }

        .article-wrapper {
            padding: 20px 20px 0;
            width: 100%;
        }

        .article-title {
            font-size: 1.2rem;
        }

        .article-info {
            margin: 4px 0;
            font-size: 0.6rem;
        }

        .article-content {
            font-size: 0.9rem;
        }
    }

    .home-blog-wrapper {
        position: sticky !important;
        top: 10px !important;
    }


    .author {
        margin-top: 20px;
    }

    .author-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

    }


    .author-img {
        border-radius: 20px !important;
        transition: all 0.5s;
    }

    .author-wrapper:hover .author-img {
        transform: rotate(360deg);
    }

    .author-name {
        font-size: 1.375rem;
    }

    .author-motto {
        font-size: 0.875rem;
    }

    .blog-info-wrapper {
        display: flex;
        text-align: center;
        padding: 0 5px;
    }

    .blog-info-data {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    .blog-info-data span {
        padding-bottom: 10px;
    }

    .blog-bookmark {
        display: block;
        position: relative;
        z-index: 1;
        padding: 5px 0;
        color: #ffffff !important;
        text-align: center;
        border-radius: 0 !important;
        background-color: #4ab1f5;
    }

    .blog-bookmark:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: -1;
        background-color: #11f3e1;
        transform: scaleX(0);
        transition: all 0.5s ease-out;
        transform-origin: left;
    }

    .blog-bookmark:hover:before {
        transition-timing-function: cubic-bezier(0.45, 1.64, 0.47, 0.66);
        transform: scaleX(1);
    }

    .card-info-social {
        display: flex;
        justify-content: center;
    }

    .card-info-social a {
        display: block;
        font-size: 1.5rem;
    }

    .card-info-social a:nth-child(2) {
        margin: 0 25px;
    }

    .hot span {
        display: block;
    }


    @keyframes zoom {
        0% {
            transform: scale(1);
        }

        100% {
            transform: scale(0.9);
        }
    }

    .blog-bell {
        animation: zoom 0.3s linear infinite alternate;
    }


    .web-info p {
        margin-bottom: 0;
        margin-top: 10px;
        display: flex;
        font-size: .875rem;
        justify-content: space-between;
    }


    .is-top {
        color: #ff0000;
    }

</style>
